<template>
    <div>
        <!--sagar changes on 12-09-2023 is_Clickable =1 means not able see other staff data  -->
        <!-- <router-link :to="row.ResumePath" > {{ row.ResumePath }}</router-link>  -->
<p @click="view(row.ResumePath )" style="cursor: pointer;color:blue">
    <!-- {{ row.ResumePath }} -->
    View
</p>
    </div>
</template>

<script>
import config from "Config/base";
    export default {
        name: "ReferenceCell",
        props: {
            row: {
                type: Object,
                required: true
            }
        },
        computed: {
            link: function () {
                // return this.row.resumePath;
                console.log(this.row.ResumePath)
           
                return `/api/${this.row.ResumePath}`;
            }
        },
        methods: {
            view(v){
                     return window.open(config["imageUrl"]+'wpConnector/' + v,'_blank' );
              
            }
        },
    }
</script>
