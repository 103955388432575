
import viewResume from '../viewResume'

export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Name",
    key: "Name",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Email",
    key: "Email",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Phone",
    key: "Phone",
    // sortable: true,
    minWidth: 80,
  },

 
  {
    title: "Qualification",
    key: "Qualification",
    // sortable: true,
    minWidth: 80,
  },

  // {
  //   title: "Position",
  //   key: "Position",
  //   // sortable: true,
  //   minWidth: 80,
  // },
    {
    title: "Experience",
    key: "Experience",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: 'Resume',
    key: 'ResumePath',
    minWidth: 80,
    render: (h, params) => {
        return h(viewResume, {
            props: {
                row: params.row
            }
        });
    },
},
  //  {
  //   title: "BriefSummary",
  //   key: "BriefSummary",
  //   // sortable: true,
  //   minWidth: 80,
  // },

];
