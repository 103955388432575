<template>
    <div style="display:flex;column-gap: 70px;">
            <div :class="$style.container">
                <table style="width:100%;">
                    <tr>
                        <td style="width:25%; font-size: 14px; text-indent: initial;">Full Name</td>
                        <td style="width:25%; font-size: 14px; text-indent: initial;">Email</td>
                        <td style="width:25%; font-size: 14px; text-indent: initial;">Phone</td>
                        <td style="width:25%; font-size: 14px; text-indent: initial;">DOB</td>
                    </tr>
                    <tr>
                        <td>{{items.Name}}</td>
                        <td>{{items.Email}}</td>
                        <td>{{items.Phone}}</td>
                        <td>{{items.DOB}}</td>
                    </tr>
                </table>
                <br>
                <table style="width:100%;">
                    <tr>
                        <td style="width:25%;font-size: 14px; text-indent: initial;">Gender</td>
                        <td style="width:25%;font-size: 14px; text-indent: initial;">Qualification</td>
                        <td style="width:25%;font-size: 14px; text-indent: initial;">Years of Experience</td>
                        <td style="width:25%;"></td>
                    </tr>
                    <tr>
                        <td>{{items.Gender=='M'?'Male':'Female'}}</td>
                        <td>{{items.Qualification}}</td>
                        <td>{{items.Experience}}</td>
                        <td></td>
                    </tr>
                </table>
                <br>
                <table style="width:100%;">
                    <tr>
                        <td style="width:10%;font-size: 14px; text-indent: initial;">Brief Profile: </td>
                        <td style="width:90%;"> : {{items.BriefSummary.length>250?items.BriefSummary.substr(0,250)+'....':items.BriefSummary}}</td>
                    </tr>
                </table>
                <br>
                <table style="width:100%;">
                    <tr>
                        <td style="width:50%; margin:auto;"><a @click="resumeView(items.ResumePath)"> Click to View Resume</a> &nbsp;  &nbsp;<a @click="view(items.Id,items.Status)">Click to View Complete Details</a></td>
                        <!-- <td style="width:50%;margin:auto;"><a @click="view(items.Id,items.Status)">Click to View Complete Details</a></td> -->
                    </tr>
                </table>
            </div>
    </div>
</template>
<script>
 import moment from "moment";
 import config from "Config/base";
    export default {
        props: {
            items: {
                // type: Array,
                required: true,
                default: () => [],
            }
        },
        created(){
console.log(this.items)
        },
        methods: {
            // click(item, items) {
            //     this.$emit('onClick', item, items);
            // },
            // taskList() {
            //      this.$router.push({ path: '/ToDoList' });
            // },
            // dateFormat(date){
            //     return moment(date).format('DD-MMM-YYYY');
            // },
            view(id,status) {
  window.open(`/application/view/${btoa(id)}&${btoa(parseInt(atob(this.$route.params.id)))}&${btoa(parseInt(status))}`,'_blank')
        // this.$router.push({
        //   path: `/application/view/${btoa(id)}&${btoa(parseInt(atob(this.$route.params.id)))}&${btoa(parseInt(status))}`,
        // });
    
    },
            resumeView(path){
window.open(
  config["imageUrl"]+'wpConnector/' + path,
  '_blank' )
    },
        }
    }
</script>
<style lang="scss" module>
    .item {
        display: flex;
        span {
            width: 100%;
        }
        b {
            white-space: nowrap;
        }
    }

    .headerText{
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 400;
    }

    .container{
        padding: 5px 0px 5px 10px;
        width: 100%;
        color: #515a6e;
        list-style-type:none;
        // border-bottom: #81c3ec solid 1px;
    }

    .img{
        // height: 25px;
        // margin-left: 95%;
        // // position: relative;
        // // margin-top: -22px;
        height: 90%;
    width: 100%;
    cursor: pointer;
    }

    .containerCount{
        color: #81c3ec;
        margin-bottom: 20px;
        font-size: 16px;
        cursor: pointer;
        float: right;
        font-weight: bolder;
        &:focus,
        &:hover {
            color: orange
        }
    }
</style>
